import { getTracesSampleRate } from "@web/utils/analytics/getTracesSampleRate";
import { sentryBeforeSend } from "@web/utils/analytics/sentryBeforeSend";
import { getEnvironment } from "@web/utils/env/getEnvironment";

export const SENTRY_SHARED_OPTIONS = {
  attachStacktrace: true,
  beforeSend: sentryBeforeSend,
  dsn: "https://da2e919041494b0abf5f3237b4058a9a@o4504347899592704.ingest.sentry.io/4504749907247104",
  environment: getEnvironment(),
  tracesSampleRate: getTracesSampleRate(),
};
